<template>
<div>
    <div style="padding-top:0px">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout" style="overflow-x:scroll">
                    <a-col :span="24">
                        <span class="s-o-font1">관심 제품 관리</span>
                    </a-col>
                    <a-col class="fav" :span="24" style="margin-top:18px;min-width:1250px">
                        <div class="fav_main" style="background-color:white;width:100%;border-bottom:1px solid #dbdbdb;" v-bind:class="{ active: expandable == true }">
                            <div style="margin-left:20px;display:flex">
                                <div style="margin-top:20px;">
                                    <a-select default-value="네이버스토어" style="width: 200px" size="large" @change="onSelectStoreType">
                                        <a-select-option value="네이버스토어">
                                            네이버스토어
                                        </a-select-option>
                                        <a-select-option value="쿠팡">
                                            쿠팡
                                        </a-select-option>
                                        <a-select-option value="티몬">
                                            티몬
                                        </a-select-option>
                                        <a-select-option value="카카오쇼핑">
                                            카카오쇼핑
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <div style="margin-top:20px;">
                                    <a-input v-model="storename" placeholder="상점명" :disabled="loadable" @pressEnter="onLoad()" size="large" />
                                </div>
                                <div style="margin-top:20px;" class="s-o-btn1">
                                    <a-input-search v-model="keywordname" placeholder="키워드" size="large" :disabled="loadable" @search="onLoad()">
                                        <a-button :loading="loadable" slot="enterButton" :disabled="!license" style="color:white">
                                            검색
                                        </a-button>
                                    </a-input-search>

                                </div>
                                <div class="fav-button" style="position:absolute;right:0;margin-right:10px;margin-top:20px">
                                    <a-button @click="modal4Visible = true" v-bind:class="{ active: selectedRows.length >0 }" :disabled="selectedRows.length==0||!license" size="large" style="margin-right:10px">
                                        <i class="fal fa-layer-group"></i><span style="padding-left:5px">그룹지정</span>
                                    </a-button>
                                    <a-button @click="onViewMultiData()" v-bind:class="{ active: selectedRows.length >0 }" :disabled="selectedRows.length==0||!license" size="large" style="margin-right:10px">
                                        <i class="far fa-analytics"></i><span style="padding-left:5px">비교</span>
                                    </a-button>
                                    <a-button @click="onDeletewMultiData()" v-bind:class="{ active: selectedRows.length >0 }" :disabled="selectedRows.length==0" size="large">
                                        <i class="fal fa-trash-alt"></i><span style="padding-left:5px">삭제</span>
                                    </a-button>
                                </div>
                                <div style="margin-top:20px;margin-left:10px">
                                    <a-select default-value="" style="width: 200px" size="large" @change="onSearchGroupName">
                                        <a-select-option :value="item.key" v-for="item in OPTIONS" :key="item.key">
                                            {{item.val}}
                                        </a-select-option>
                                    </a-select>
                                </div>

                            </div>
                            <div style="margin-left:20px;margin-top:5px">
                                <a-collapse style="margin-right:9px;margin-left:-1px" @change="onExpandFilter">
                                    <a-collapse-panel key="1" header="검색 필터">
                                        <div class="fav-button">
                                            <a-radio-group :options="plainOptions" default-value="페이지 형태" @change="onChangeViewMode" />
                                            <a-button @click="onSortBy(SORT_BY_HIGH_RANK)" v-bind:class="{ active: tableData.length >0 }" :disabled="tableData.length==0||!license" size="large" style="margin-right:10px">
                                                현재 순위 높은순 정렬
                                            </a-button>
                                            <a-button @click="onSortBy(SORT_BY_LOW_RANK)" v-bind:class="{ active: tableData.length >0 }" :disabled="tableData.length==0||!license" size="large" style="margin-right:10px">
                                                현재 순위 낮은순 정렬
                                            </a-button>
                                            <a-button @click="onSortBy(SORT_BY_HIGH_GAP)" v-bind:class="{ active: tableData.length >0 }" :disabled="tableData.length==0||!license" size="large" style="margin-right:10px">
                                                전일 대비 순위가 많이 증가한 순 정렬
                                            </a-button>
                                            <a-button @click="onSortBy(SORT_BY_LOW_GAP)" v-bind:class="{ active: tableData.length >0 }" :disabled="tableData.length==0||!license" size="large" style="margin-right:10px">
                                                전일 대비 순위가 많이 떨어진 순 정렬
                                            </a-button>
                                        </div>
                                    </a-collapse-panel>

                                </a-collapse>
                            </div>
                        </div>

                        <div style="margin:10px;">

                            <a-table :columns="columns" :pagination="pagination" :data-source="tableData" :customRow="rowclick" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :loading="loading" :scroll="{ x :1500 }">

                                <div slot="keyword" slot-scope="record">
                                    <a-tag v-if="record.md=='T'" color="blue">MD</a-tag> {{record.keyword}}
                                </div>
                                <div slot="ad" slot-scope="text">
                                    <div v-if="text=='T'">
                                        <a-tag color="green">광고</a-tag>
                                    </div>
                                    <div v-if="text=='7'">
                                        <a-tag color="cyan">럭키투데이</a-tag>
                                    </div>
                                </div>
                                <div slot="age" slot-scope="record" v-if="storetype=='네이버스토어'">
                                    <span>{{record.age}}</span>
                                </div>
                                <div slot="color" slot-scope="record" v-if="storetype=='네이버스토어'">
                                    <div class="scolor color1 selected" v-if="record.color==16"></div>
                                    <div class="scolor color2 selected" v-if="record.color==64"></div>
                                    <div class="scolor color3 selected" v-if="record.color==128"></div>
                                    <div class="scolor color4 selected" v-if="record.color==256"></div>
                                    <div class="scolor color5 selected" v-if="record.color==1024"></div>
                                    <div class="scolor color6 selected" v-if="record.color==2048"></div>
                                    <div class="scolor color7 selected" v-if="record.color==4096"></div>
                                    <div class="scolor color8 selected" v-if="record.color==8192"></div>
                                    <div class="scolor color9 selected" v-if="record.color==16384"></div>
                                    <div class="scolor color10 selected" v-if="record.color==8"></div>
                                    <div class="scolor color11 selected" v-if="record.color==4"></div>
                                    <div class="scolor color12 selected" v-if="record.color==1"></div>
                                </div>
                                <div slot="gender" slot-scope="record" v-if="storetype=='네이버스토어'">
                                    <span>{{record.gender}}</span>
                                </div>

                                <div class="rank_1" v-bind:class="getRankStatus(record)" slot="today" slot-scope="record">
                                    <div style="display:flex">
                                        <div v-if="record.crank1 >0">
                                            <div v-if="viewMode=='페이지 형태'">
                                                <span>{{record.crank1}}순위</span><br />
                                                <span>({{record.cpage1}}페이지)</span>
                                            </div>
                                            <div v-else style="padding-top:2px">
                                                <span>{{record.cum1}}순위</span>
                                            </div>
                                        </div>
                                        <div v-if="record.crank1 >0 && viewMode=='페이지 형태'" style="padding-left:5px;padding-top:10px;">
                                            <span v-if="getRankStatus(record).status=='same'">(-)</span>
                                            <span v-if="getRankStatus(record).status=='up'" style="color:red"><i class="fas fa-caret-up"></i><span style="padding-left:2px">{{getRankStatus(record).value}}</span></span>
                                            <span v-if="getRankStatus(record).status=='down'" style="color:blue"><i class="fas fa-caret-down"></i><span style="padding-left:2px">{{getRankStatus(record).value}}</span></span>
                                        </div>
                                        <div v-if="record.crank1 >0 && viewMode=='순위 형태'" style="padding-left:5px;padding-top:2px;">
                                            <span v-if="getRankStatus(record).status=='same'">(-)</span>
                                            <span v-if="getRankStatus(record).status=='up'" style="color:red"><i class="fas fa-caret-up"></i><span style="padding-left:2px">{{getRankStatus(record).value}}</span></span>
                                            <span v-if="getRankStatus(record).status=='down'" style="color:blue"><i class="fas fa-caret-down"></i><span style="padding-left:2px">{{getRankStatus(record).value}}</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="rank_2" slot="tomorrow" slot-scope="record" v-if="record.crank2 >0">
                                    <div v-if="viewMode=='페이지 형태'">
                                        <span>{{record.crank2}}순위</span><br />
                                        <span>({{record.cpage2}}페이지)</span>
                                    </div>
                                    <div v-else style="padding-top:0px">
                                        <span>{{record.cum2}}순위</span>
                                    </div>
                                </div>
                                <div slot="productid" slot-scope="record">
                                    <a :href="record.href" target="_blank">
                                        {{record.productid}}
                                    </a>
                                </div>
                                <div slot="action" slot-scope="text, record">

                                    <a-button :disabled="!record.crank1" type="primary" @click="onViewChart(record)"> 차트 보기 </a-button>

                                </div>
                            </a-table>
                        </div>
                    </a-col>
                </a-row>

            </a-layout-content>
        </a-layout>

    </div>

    <a-modal v-model="modal2Visible" title="순위 챠트 [최근 7일]" centered width="900px">
        <template slot="footer">
            <a-button key="submit" type="primary" @click="modal2Visible=false">
                닫기
            </a-button>
        </template>
        <apexchart width="100%" height="300" type="area" :options="defaultOption" :series="selectedData"></apexchart>
    </a-modal>

    <a-modal v-model="modal3Visible" title="순위 챠트" centered width="1400px">
        <template slot="footer">
            <a-button key="submit" type="primary" @click="modal3Visible=false">
                닫기
            </a-button>
        </template>
        <div style="display:flex;margin-bottom:10px">
            <div style="margin-top:0px;z-index: 9;">
                <a-date-picker :defaultValue="date.fromdate" @change="onChangeDateFrom" format="YYYY-MM-DD" placeholder="시작 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
            </div>
            <div style="margin-top:0;margin-left:5px;margin-right:5px;padding-top:10px">
                ~
            </div>
            <div style="margin-top:0px;z-index: 9;">
                <a-date-picker :defaultValue="date.todate" @change="onChangeDateTo" format="YYYY-MM-DD" placeholder="종료 날짜 선택" :disabledDate="disabledDateEx" :locale="locale" />
            </div>
            <div class="s-h-btn1" @click="onSearchMultiData()" style="margin-top:0px;;margin-left:5px;z-index: 9;">
                <a-button :disabled="!date.fromdateD||!date.todateD" style="width:60px">검색</a-button>
            </div>
        </div>
        <apexchart width="100%" height="600" type="area" :options="chartOptions" :series="selectedDataMulti"></apexchart>
    </a-modal>

    <a-modal title="그룹 관리" v-model="modal4Visible" width="700px">
        <template slot="footer">
            <a-button key="back" @click="modal4Visible=false">닫기</a-button>
        </template>
        <div class="row">
            <div class="col-md-12">
                <a-input style="width:77%" v-model="groupname" placeholder="그룹명" />
                <a-button style="width:20%;margin-left:5px" type="primary" @click="addNewGroup()" :disabled="groupname==''">추가</a-button>

            </div>

            <div class="col-md-12" style="margin-top:10px">

                <a-table :columns="groupcolumns" :pagination="paginationGroup" :data-source="getGroupData" :loading="loading" :scroll="{  x : 0, y: 400 }">

                    <div slot="action" slot-scope="record" style="display:flex">
                        <a-button style="margin-right:5px" @click="onSelectGroupName(record)">그룹선택</a-button>
                        <a-popconfirm title="그룹명을 삭제 하시겠습니까?" @confirm="confirmDelete" @cancel="cancelDelete" okText="네" cancelText="아니요">
                            <a-button type="danger" icon="delete" @click="removeGroup(record)">삭제</a-button>
                        </a-popconfirm>
                    </div>
                </a-table>

            </div>

        </div>
    </a-modal>

    <nav-footer></nav-footer>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const SORT_BY_HIGH_RANK = 1;
const SORT_BY_LOW_RANK = 2;
const SORT_BY_HIGH_GAP = 3;
const SORT_BY_LOW_GAP = 4;

import SubMenu from "../../layouts/nav/SubMenu";
import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  getCurrentDate2,
  getCurrentTime,
  getLicense,
  chkLicenseForDelete,
  showError,
  showSuccess,
  isMobile
} from "../components/fnc.js";
export default {
  computed: {
    getGroupData() {
      var _tmp = [];
      var self = this;
      this.OPTIONS.forEach(item => {
        if (item.key != "_ALL_" && item.key != "-") {
          if (self.groupname == "" || item.val.indexOf(self.groupname) >= 0) {
            // console.log("s", item);
            _tmp.push({
              name: item.val,
              key: item.key
            });
          }
        }
      });
      return _tmp;
    },
    SORT_BY_HIGH_RANK() {
      return 1;
    },
    SORT_BY_LOW_RANK() {
      return 2;
    },
    SORT_BY_HIGH_GAP() {
      return 3;
    },
    SORT_BY_LOW_GAP() {
      return 4;
    },
    getTableWidth() {
      return "width:" + (window.innerWidth - 170) + "px";
    },
    getHeight() {
      return window.innerHeight - 500;
    },
    getCreatedTime() {
      return getCurrentTime(
        new Date(this.$store.state.AppActiveUser.createdAt)
      );
    },
    getMargin() {
      return this.$store.state.AppActiveUser.remain > 0
        ? this.$store.state.AppActiveUser.remain
        : 0;
    },
    getExpireDate() {
      return getCurrentTime(
        new Date().addDays(this.$store.state.AppActiveUser.remain)
      );
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getLevel() {
      return this.$store.state.AppActiveUser.level;
    },
    getMD() {
      return this.$store.state.AppActiveUser.ismd;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  data() {
    return {
      unclickable: false,
      groupkey: "",
      viewMode: "페이지 형태",
      expandable: false,
      plainOptions: ["페이지 형태", "순위 형태"],
      pagination: {
        pageSize: 10, // default number of pages per page
        showSizeChanger: true, // display can change the number of pages per page
        pageSizeOptions: ["10", "20", "30", "40"], // number of pages per option
        showTotal: total => `Total ${total} 개`, // show total
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize) // update display when changing the number of pages per page
      },
      paginationGroup: {
        pageSize: 10, // default number of pages per page
        showSizeChanger: true, // display can change the number of pages per page
        pageSizeOptions: ["10", "20", "30", "40"], // number of pages per option
        showTotal: total => `Total ${total} 개`, // show total
        showSizeChange: (current, pageSize) => (this.pageGroupSize = pageSize) // update display when changing the number of pages per page
      },
      pageSize: "10",
      pageGroupSize: "10",
      selectedRowKeys: [],
      selectedGroupRowKeys: [],
      license: false,
      date: {
        fromdate: moment(new Date().addDays(-90)),
        todate: moment(new Date().addDays(1)),
        fromdateD: new Date().addDays(-90),
        todateD: new Date().addDays(1)
      },
      date7: {
        fromdate: moment(new Date().addDays(-7)),
        todate: moment(new Date().addDays(1)),
        fromdateD: new Date().addDays(-7),
        todateD: new Date().addDays(1)
      },
      loadable: false,
      includeMD: false,
      selectedRows: [],
      selectedData: [],
      selectedDataMulti: [],
      autoHistory: [],
      OPTIONS: [],
      locale,
      groupname: "",
      currentrow: null,
      autoHistoryNaver: [],
      autoHistoryCoupang: [],
      autoHistoryTmon: [],
      autoHistoryKakao: [],
      tableData: [],
      modal2Visible: false,
      modal3Visible: false,
      modal4Visible: false,
      loading: false,
      storetype: "네이버스토어",
      chartOptions: {
        chart: {
          locales: [
            {
              name: "ko",
              options: {
                months: [
                  "1월",
                  "2월",
                  "3월",
                  "4월",
                  "5월",
                  "6월",
                  "7월",
                  "8월",
                  "9월",
                  "10월",
                  "11월",
                  "12월"
                ],
                shortMonths: [
                  "'1",
                  "'2",
                  "'3",
                  "'4",
                  "'5",
                  "'6",
                  "'7",
                  "'8",
                  "'9",
                  "'10",
                  "'11",
                  "'12"
                ],
                days: [
                  "일요일",
                  "월요일",
                  "화요일",
                  "수요일",
                  "목요일",
                  "금요일",
                  "토요일"
                ],
                shortDays: ["일", "월", "화", "수", "목", "금", "토"],
                toolbar: {
                  exportToSVG: "SVG 다운로드",
                  exportToPNG: "PNG 다운로드",
                  menu: "메뉴",
                  selection: "선택",
                  selectionZoom: "선택 확대",
                  zoomIn: "줌인",
                  zoomOut: "줌아웃",
                  pan: "팬",
                  reset: "줌초기화"
                }
              }
            }
          ],
          defaultLocale: "ko",
          stacked: false,
          zoom: {
            type: "x",
            enabled: true
          },
          toolbar: {
            autoSelected: "zoom"
          }
        },
        colors: ["#008FFB"],
        plotOptions: {
          line: {
            curve: "smooth"
          }
        },
        colors: [
          "#6cd6aa",
          "#f28caf",
          "#cb50e9",
          "#727dd6",
          "#aba9a9",
          "#f6db5f",
          "#ffb554",
          "#fe5e51",
          "#9e3d64",
          "#36abb5"
        ],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: "스토어 순위",
          align: "left"
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 6
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            formatter: function(value) {
              return getCurrentDate2(new Date(value));
            }
          }
        },
        yaxis: {
          reversed: true,
          title: {
            text: "렝킹"
          },
          min: 0,
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-yaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: value => {
              return parseInt(value);
            }
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          floating: true,
          offsetY: -10,
          offsetX: 0
        }
      },
      defaultOption: {
        chart: {
          locales: [
            {
              name: "ko",
              options: {
                months: [
                  "1월",
                  "2월",
                  "3월",
                  "4월",
                  "5월",
                  "6월",
                  "7월",
                  "8월",
                  "9월",
                  "10월",
                  "11월",
                  "12월"
                ],
                shortMonths: [
                  "'1",
                  "'2",
                  "'3",
                  "'4",
                  "'5",
                  "'6",
                  "'7",
                  "'8",
                  "'9",
                  "'10",
                  "'11",
                  "'12"
                ],
                days: [
                  "일요일",
                  "월요일",
                  "화요일",
                  "수요일",
                  "목요일",
                  "금요일",
                  "토요일"
                ],
                shortDays: ["일", "월", "화", "수", "목", "금", "토"],
                toolbar: {
                  exportToSVG: "SVG 다운로드",
                  exportToPNG: "PNG 다운로드",
                  menu: "메뉴",
                  selection: "선택",
                  selectionZoom: "선택 확대",
                  zoomIn: "줌인",
                  zoomOut: "줌아웃",
                  pan: "팬",
                  reset: "줌초기화"
                }
              }
            }
          ],
          defaultLocale: "ko",
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 6
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            formatter: function(value) {
              return getCurrentDate2(new Date(value));
            }
          }
        },
        yaxis: {
          reversed: true,
          title: {
            text: "렝킹"
          },
          min: 0,
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-yaxis-label"
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: value => {
              return parseInt(value);
            }
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      columns: [],
      groupcolumns: [
        {
          title: "그룹명",
          key: "name",
          align: "left",
          dataIndex: "name"
        },
        {
          title: "Action",
          width: "200px",
          key: "action",
          align: "center",
          scopedSlots: {
            customRender: "action"
          }
        }
      ]
    };
  },
  components: {
    SubMenu,
    NavFooter
  },
  async mounted() {
    if (this._mobile) {
      this.$router.push("/pages/notsupport").catch(() => {});
    } else {
      this.license = chkLicenseForDelete({
        notify: this.$vs.notify
      });

      this.onInitStoreColumn();
      this.loadable = true;
      this.selectedRows = [];

      if (this.getUid) {
        await this.onLoadGroupList();
        // this.countDown();
        await this.onLoadAutoHistory();
        await this.onLoadAutoHistoryCoupang();
        await this.onLoadAutoHistoryTmon();
        await this.onLoadAutoHistoryKakao();
        this.loadable = false;
        this.onLoad();
      } else {
        this.loadable = false;
      }
    }
  },
  methods: {
    rowclick(record, index) {
      if (this.unclickable == false) {
        return {
          on: {
            click: () => {
              var _find = false;
              this.selectedRowKeys = this.selectedRowKeys.filter(item => {
                if (item == record.id) {
                  //pop
                  _find = true;
                } else {
                  return item;
                }
              });

              this.selectedRows = this.selectedRows.filter(item => {
                if (item.id == record.id) {
                  //pop
                } else {
                  return item;
                }
              });

              if (!_find) {
                this.selectedRowKeys.push(record.id);
                this.selectedRows.push(record);
              }
            },
            dblclick: () => {}
          }
        };
      }
    },
    onSearchGroupName(e) {
      this.groupkey = e;
      this.onLoad();
    },
    addNewGroup() {
      var self = this;

      if (this.OPTIONS.find(item => item.val == this.groupname)) {
        showError({
          notify: this.$vs.notify,
          msg: "이미 등록된 그룹명입니다."
        });
      } else {
        var newPostKey = firebase
          .database()
          .ref()
          .child("keyword_group/")
          .push().key;

        var d2 = new Date();
        var d = getCurrentTimeNew();

        firebase
          .database()
          .ref("keyword_group/" + newPostKey)
          .set({
            uid: this.getUid,
            createdAt: d2.getTime(),
            eventdttm: d,
            reverseCreatedAt: -d2.getTime(),
            groupname: this.groupname
          })
          .then(function(result) {
            //nothing
            self.OPTIONS.push({
              key: newPostKey,
              val: self.groupname
            });
            showSuccess({
              notify: self.$vs.notify,
              msg: "성공적으로 등록되었습니다."
            });
            self.currentrow = null;
          });
      }
    },
    modifyGroup() {
      var d2 = new Date();
      var d = getCurrentTime();
      var _id = this.currentrow.key;

      this.OPTIONS = this.OPTIONS.filter(item => {
        if (item.key == _id) {
          item.val = this.groupname;
        }
        return item;
      });

      var uref = firebase.database().ref("keyword_group/" + _id);

      var upData = {
        uid: this.$store.state.uid,
        createdAt: d2.getTime(),
        eventdttm: d,
        reverseCreatedAt: -d2.getTime(),
        groupname: this.groupname
      };

      uref.update(upData);
      this.groupname = "";
      this.setCurrent();

      this.$message.info("정상적으로 변경 되었습니다");
      this.currentrow = null;
    },
    removeGroup(row) {
      this.currentrow = row;
    },
    onLoadGroupList() {
      var self = this;
      var idx = 0;

      var sref = firebase.database().ref("keyword_group/");
      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          self.OPTIONS = [];
          sdata.forEach(function(sdatas) {
            if (self.OPTIONS.find(o => o.val == sdatas.val().groupname)) {
            } else {
              self.OPTIONS.push({
                key: sdatas.key,
                val: sdatas.val().groupname
              });
            }
          });

          self.OPTIONS = [
            {
              key: "_ALL_",
              val: "전체"
            },
            {
              key: "-",
              val: "<그룹없음>"
            },
            ...self.OPTIONS.sort(function(a, b) {
              // desc
              return a.val < b.val ? -1 : a.val > b.val ? 1 : 0;
            })
          ];
        });
    },
    onSelectGroupName(obj) {
      var self = this;
      // console.log(obj);
      this.selectedRows.forEach(item => {
        this.tableData = this.tableData.filter(i => {
          if (i.id == item.id) {
            i.groupname = obj.name;
          }
          return i;
        });

        // console.log(item);

        var _tblname = "execution_master/";
        if (this.storetype == "쿠팡") {
          _tblname = "execution_master_coupang/";
        } else if (this.storetype == "티몬") {
          _tblname = "execution_master_tmon/";
        } else if (this.storetype == "카카오쇼핑") {
          _tblname = "execution_master_kakao/";
        }
        var uref = firebase.database().ref(_tblname + item.id);
        var upData = {
          groupkey: obj.key
        };
        uref.update(upData);

        showSuccess({
          notify: self.$vs.notify,
          msg: "성공적으로 등록되었습니다."
        });
        this.modal4Visible = false;
      });
    },
    onSortBy(mode) {
      if (mode == SORT_BY_LOW_RANK) {
        var _dummy = this.tableData;
        this.tableData = _dummy.sort(function(a, b) {
          // desc
          return a.cum1 > b.cum1 ? -1 : a.cum1 < b.cum1 ? 1 : 0;
        });
      } else if (mode == SORT_BY_HIGH_RANK) {
        var _dummy = this.tableData;
        this.tableData = _dummy.sort(function(a, b) {
          // desc
          return a.cum1 < b.cum1 ? -1 : a.cum1 > b.cum1 ? 1 : 0;
        });
      } else if (mode == SORT_BY_HIGH_GAP) {
        var _dummy = this.tableData;
        this.tableData = _dummy.sort(function(a, b) {
          // desc
          return a.gap > b.gap ? -1 : a.gap < b.gap ? 1 : 0;
        });
      } else if (mode == SORT_BY_LOW_GAP) {
        var _dummy = this.tableData;
        this.tableData = _dummy.sort(function(a, b) {
          // desc
          return a.gap < b.gap ? -1 : a.gap > b.gap ? 1 : 0;
        });
      }
    },
    onChangeViewMode(evt) {
      this.viewMode = evt.target.value;
    },
    onExpandFilter(evt) {
      var _e = evt;
      if (_e == "1") {
        this.expandable = true;
      } else {
        this.expandable = false;
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onSelectGroupChange(selectedRowKeys, selectedRows) {
      this.selectedGroupRowKeys = selectedRowKeys;
    },
    disabledDateEx(current) {
      var _default = -7;

      if (getLicense() == 1) {
        _default = -6 * 30;
      }

      if (getLicense() == 2) {
        _default = -12 * 30;
      }

      if (getLicense() == 3) {
        _default = -36 * 30;
      }

      return current && current < moment(new Date().addDays(_default));
    },
    onChangeDateFrom(e) {
      if (e) {
        this.date.fromdateD = e._d;
      }
      // this.date.fromdateD = e._d;
    },
    onChangeDateTo(e) {
      if (e) {
        this.date.todateD = e._d;
      }
    },
    onViewMultiData() {
      // this.multipleSelection = val;

      this.modal3Visible = true;

      this.onSearchMultiData();
    },
    onDeletewMultiData() {
      var self = this;
      if (this.selectedRows.length < 1) {
        showError({
          notify: this.$vs.notify,
          msg: "선택된 항목이 없습니다."
        });
      } else {
        var _tblname = "execution_master/";
        var _autoTblName = "auto_execution_master/";
        if (this.storetype == "쿠팡") {
          _tblname = "execution_master_coupang/";
          _autoTblName = "auto_execution_master_coupang/";
        } else if (this.storetype == "티몬") {
          _tblname = "execution_master_tmon/";
          _autoTblName = "auto_execution_master_tmon/";
        } else if (this.storetype == "카카오쇼핑") {
          _tblname = "execution_master_kakao/";
          _autoTblName = "auto_execution_master_kakao/";
        }

        this.$confirm({
          title: "확인",
          content: "선택된 항목을 삭제 하시겠습니까?",
          okText: "삭제",
          cancelText: "취소",
          onOk() {
            self.onDeleteData(_tblname, _autoTblName);
          },
          onCancel() {}
        });
      }
    },
    onDeleteData(_tblname, _autoTblName) {
      this.selectedRows.forEach(item => {
        if (item.id) {
          var sref = firebase.database().ref(_tblname + item.id + "/");
          sref
            .remove()
            .then(function() {
              //
            })
            .catch(function(error) {
              console.log("Remove failed: " + error.message);
            });

          var sref2 = firebase.database().ref(_autoTblName + item.id + "/");
          sref2
            .remove()
            .then(function() {
              //
            })
            .catch(function(error) {
              console.log("Remove failed: " + error.message);
            });

          this.tableData = this.tableData.filter(sitem => {
            if (sitem.id == item.id) {
              //skip
            } else {
              return sitem;
            }
          });
        }
      });

      showSuccess({
        notify: this.$vs.notify,
        msg: "정상적으로 삭제 되었습니다."
      });
    },

    confirmDelete(e) {
      // console.log(e);
      // this.$message.success("Click on Yes");
      if (this.currentrow) {
        this.OPTIONS = this.OPTIONS.filter(item => {
          if (item.key == this.currentrow.key) {
            //skip
          } else {
            return item;
          }
        });

        if (this.currentrow.key) {
          var sref = firebase
            .database()
            .ref("keyword_group/" + this.currentrow.key + "/");
          sref.remove();
        }
      }
      this.groupname = "";
      this.currentrow = null;
    },
    cancelDelete(e) {
      this.currentrow = null;
      // this.$message.error("Click on No");
    },
    onSearchMultiData() {
      this.selectedDataMulti = [];
      var self = this;

      this.selectedRows.forEach(item => {
        if (this.storetype == "네이버스토어") {
          this.onLoadRangeAutoHistory(
            "auto_execution_history",
            item,
            item.productid.toString()
          );
        } else if (this.storetype == "카카오쇼핑") {
          this.onLoadRangeAutoHistory(
            "auto_execution_history_kakao",
            item,
            item.productid
          );
        } else if (this.storetype == "티몬") {
          this.onLoadRangeAutoHistory(
            "auto_execution_history_tmon",
            item,
            item.productid.toString()
          );
        } else if (this.storetype == "쿠팡") {
          this.onLoadRangeAutoHistory(
            "auto_execution_history_coupang",
            item,
            item.productid.toString()
          );
        }
      });
    },
    countDown() {
      let secondsToGo = 3;
      const modal = this.$success({
        title: "알림",
        content: `검색을 위한 기준 데이타를 로딩중입니다. 이 메세지는 자동으로 ${secondsToGo} 초후 사라집니다.`
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `검색을 위한 기준 데이타를 로딩중입니다. 이 메세지는 자동으로 ${secondsToGo} 초후 사라집니다.`
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
    },
    getRankStatus(item) {
      var _t = {
        status: "same",
        value: 0
      };

      if (!item["cum2"]) {
        return _t;
      }

      var _diff = item["cum2"] - item["cum1"];
      _t.value = Math.abs(_diff);
      if (_diff < 0) {
        _t.status = "down";
      } else if (_diff > 0) {
        _t.status = "up";
      } else {
        _t.status = "same";
      }

      return _t;
    },
    onViewChart(item) {
      this.unclickable = true;
      if (this.storetype == "네이버스토어") {
        this.onLoadRangeAuto7History(
          "auto_execution_history",
          item,
          item.productid.toString()
        );
      } else if (this.storetype == "카카오쇼핑") {
        this.onLoadRangeAuto7History(
          "auto_execution_history_kakao",
          item,
          item.productid
        );
      } else if (this.storetype == "티몬") {
        this.onLoadRangeAuto7History(
          "auto_execution_history_tmon",
          item,
          item.productid.toString()
        );
      } else if (this.storetype == "쿠팡") {
        this.onLoadRangeAuto7History(
          "auto_execution_history_coupang",
          item,
          item.productid.toString()
        );
      }
    },
    onInitStoreColumn() {
      if (this.storetype == "네이버스토어") {
        this.columns = [
          {
            title: "키워드",
            key: "keyword",
            align: "left",
            width: "200px",
            scopedSlots: {
              customRender: "keyword"
            }
          },
          {
            title: "그룹명",
            dataIndex: "groupname",
            width: "200px",
            key: "groupname",
            align: "left"
          },
          {
            title: "광고유무",
            dataIndex: "ad",
            width: "80px",
            key: "ad",
            align: "center",
            scopedSlots: {
              customRender: "ad"
            }
          },
          {
            title: "오늘",
            key: "today",
            width: "130px",
            align: "center",
            scopedSlots: {
              customRender: "today"
            },
            title: () => {
              return (
                <div style="text-align:left;margin-left:-25px">
                  <span> 오늘 </span>{" "}
                </div>
              );
            }
          },
          {
            title: "어제",
            key: "tomorrow",
            width: "100px",
            align: "center",
            scopedSlots: {
              customRender: "tomorrow"
            }
          },
          {
            title: "상점명",
            key: "mallId",
            width: "250px",
            dataIndex: "mallId",
            align: "left"
          },
          {
            title: "제품명",
            key: "title",
            dataIndex: "title",
            align: "left",
            width: "250px"
          },
          {
            title: "제품코드",
            key: "productid",
            width: "150px",
            align: "center",
            scopedSlots: {
              customRender: "productid"
            }
          },
          {
            title: "연령",
            width: "100px",
            key: "age",
            align: "center",
            scopedSlots: {
              customRender: "age"
            }
          },
          {
            title: "색깔",
            width: "60px",
            key: "color",
            align: "center",
            scopedSlots: {
              customRender: "color"
            }
          },
          {
            title: "성별",
            width: "100px",
            key: "gender",
            align: "center",
            scopedSlots: {
              customRender: "gender"
            }
          },
          {
            title: "등록일",
            key: "createdAt",
            width: "200px",
            dataIndex: "createdAt",
            align: "left"
          },
          {
            title: "챠트",
            key: "action",
            width: "120px",
            align: "center",
            scopedSlots: {
              customRender: "action"
            }
          }
        ];
      } else {
        this.columns = [
          {
            title: "키워드",
            key: "keyword",
            align: "left",
            width: "200px",
            scopedSlots: {
              customRender: "keyword"
            }
          },
          {
            title: "그룹명",
            dataIndex: "groupname",
            width: "200px",
            key: "groupname",
            align: "left"
          },
          {
            title: "광고유무",
            dataIndex: "ad",
            width: "80px",
            key: "ad",
            align: "center",
            scopedSlots: {
              customRender: "ad"
            }
          },
          {
            key: "today",
            width: "130px",
            align: "center",
            scopedSlots: {
              customRender: "today"
            },
            title: () => {
              return (
                <div style="text-align:left;margin-left:-35px">
                  <span> 오늘 </span>{" "}
                </div>
              );
            }
          },
          {
            title: "어제",
            key: "tomorrow",
            width: "100px",
            align: "center",
            scopedSlots: {
              customRender: "tomorrow"
            }
          },
          {
            title: "상점명",
            key: "mallId",
            width: "250px",
            dataIndex: "mallId",
            align: "left"
          },
          {
            title: "제품명",
            key: "title",
            dataIndex: "title",
            align: "left",
            width: "250px"
          },
          {
            title: "제품코드",
            key: "productid",
            width: "150px",
            align: "center",
            scopedSlots: {
              customRender: "productid"
            }
          },
          {
            title: "등록일",
            key: "createdAt",
            width: "200px",
            dataIndex: "createdAt",
            align: "left"
          },
          {
            title: "챠트",
            key: "action",
            width: "120px",
            align: "center",
            scopedSlots: {
              customRender: "action"
            }
          }
        ];
      }
    },
    onSelectStoreType(value) {
      this.storetype = value;
      this.tableData = [];
      this.selectedRows = [];
      this.onInitStoreColumn();
    },
    onLoadRangeAutoHistory(_tblname, obj, _productId) {
      var self = this;
      var db = firebase.firestore();
      this.$vs.loading();
      var __tmp__ = [];
      db
        .collection(_tblname)
        .where("uid", "==", obj.uid)
        .where("productId", "==", _productId)
        .where("val1", "==", obj.ad)
        .where("createdAt", ">=", new Date(this.date.fromdateD).getTime())
        .where("createdAt", "<=", new Date(this.date.todateD).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (obj.keyword == doc.data().keyword) {
              var _ad = doc.data().val1;
              var _item = {
                key: doc.id,
                productId: doc.data().productId,
                keyword: doc.data().keyword,
                ad: _ad,
                cumrank: doc.data().cumrank,
                page: doc.data().page,
                rank: doc.data().rank,
                eventtime: doc.data().eventdttm,
                duplicate:
                  doc.data().productId +
                  replaceAll(doc.data().keyword, " ", "") +
                  _ad +
                  doc.data().eventdttm.substr(0, 10)
              };
              if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
                //skip
              } else {
                __tmp__.push(_item);
              }
            }
          });
          var _tmp = [];

          var ___tmp___ = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          __tmp__.forEach(item => {
            var innerArr = [item.eventtime, item.cumrank];

            _tmp.push(innerArr);
          });

          var _item = {
            name: obj.keyword,
            data: _tmp
          };
          self.selectedDataMulti.push(_item);
          self.$vs.loading.close();
        });
    },
    onLoadRangeAuto7History(_tblname, obj, _productId) {
      var self = this;
      self.selectedData = [];
      var db = firebase.firestore();
      this.$vs.loading();
      var __tmp__ = [];
      db
        .collection(_tblname)
        .where("uid", "==", obj.uid)
        .where("productId", "==", _productId)
        .where("val1", "==", obj.ad)
        .where("createdAt", ">=", new Date(this.date7.fromdateD).getTime())
        .where("createdAt", "<=", new Date(this.date7.todateD).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            if (obj.keyword == doc.data().keyword) {
              var _ad = doc.data().val1;
              var _item = {
                key: doc.id,
                productId: doc.data().productId,
                keyword: doc.data().keyword,
                ad: _ad,
                cumrank: doc.data().cumrank,
                page: doc.data().page,
                rank: doc.data().rank,
                eventtime: doc.data().eventdttm,
                duplicate:
                  doc.data().productId +
                  replaceAll(doc.data().keyword, " ", "") +
                  _ad +
                  doc.data().eventdttm.substr(0, 10)
              };
              if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
                //skip
              } else {
                __tmp__.push(_item);
              }
            }
          });
          var _tmp = [];

          var ___tmp___ = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          ___tmp___.forEach(item => {
            var innerArr = [item.eventtime, item.cumrank];

            _tmp.push(innerArr);
          });

          var _item = {
            name: obj.keyword,
            data: _tmp
          };
          self.selectedData.push(_item);
          self.modal2Visible = true;
          self.unclickable = false;
          self.$vs.loading.close();
        });
    },
    async onLoadAutoHistory() {
      var self = this;
      var db = firebase.firestore();
      var __tmp__ = [];
      await db
        .collection("auto_execution_history")
        .where("uid", "==", this.getUid)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _color = doc.data().color ? doc.data().color : "-";
            var _age = doc.data().age ? doc.data().age : "-";
            var _gender = doc.data().gender ? doc.data().gender : "-";
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              color: _color,
              age: _age,
              gender: _gender,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10) +
                _color +
                _age +
                _gender
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });

          if (self.getMD != "F") {
            self.onLoadAutoHistoryMD(__tmp__);
          } else {
            self.autoHistoryNaver = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
          }
        });
    },
    async onLoadAutoHistoryMD(__tmp__) {
      var self = this;
      var db = firebase.firestore();

      var __tmp__swap = [];
      await db
        .collection("auto_execution_history")
        .where("uid", "==", "AqIpKqGMElbw9Awzw03wSRsqv4U2")
        .where("val2", "==", this.getMD)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _color = doc.data().color ? doc.data().color : "-";
            var _age = doc.data().age ? doc.data().age : "-";
            var _gender = doc.data().gender ? doc.data().gender : "-";
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              color: _color,
              age: _age,
              gender: _gender,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10) +
                _color +
                _age +
                _gender
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });
          __tmp__swap = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          self.autoHistoryNaver = __tmp__swap;
        });
    },
    async onLoadAutoHistoryKakao() {
      var self = this;
      var db = firebase.firestore();
      var __tmp__ = [];
      await db
        .collection("auto_execution_history_kakao")
        .where("uid", "==", this.getUid)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };

            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });

          if (self.getMD != "F") {
            self.onLoadAutoHistoryKakaoMD(__tmp__);
          } else {
            self.autoHistoryKakao = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
          }
        });
    },
    async onLoadAutoHistoryKakaoMD(__tmp__) {
      var self = this;
      var db = firebase.firestore();

      var __tmp__swap = [];
      await db
        .collection("auto_execution_history_kakao")
        .where("uid", "==", "AqIpKqGMElbw9Awzw03wSRsqv4U2")
        .where("val2", "==", this.getMD)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });
          __tmp__swap = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          self.autoHistoryKakao = __tmp__swap;
        });
    },
    async onLoadAutoHistoryCoupang() {
      var self = this;
      var __tmp__ = [];
      var db = firebase.firestore();
      await db
        .collection("auto_execution_history_coupang")
        .where("uid", "==", this.getUid)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });

          if (self.getMD != "F") {
            self.onLoadAutoHistoryCoupangMD(__tmp__);
          } else {
            self.autoHistoryCoupang = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
          }
        });
    },
    async onLoadAutoHistoryCoupangMD(__tmp__) {
      var self = this;
      var db = firebase.firestore();
      var __tmp__swap = [];
      await db
        .collection("auto_execution_history_coupang")
        .where("uid", "==", "AqIpKqGMElbw9Awzw03wSRsqv4U2")
        .where("val2", "==", this.getMD)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });
          __tmp__swap = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          self.autoHistoryCoupang = __tmp__swap;
        });
    },
    async onLoadAutoHistoryTmon() {
      var self = this;
      var __tmp__ = [];
      var db = firebase.firestore();
      await db
        .collection("auto_execution_history_tmon")
        .where("uid", "==", this.getUid)
        .where("createdAt", ">=", new Date().addDays(-2).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });

          if (self.getMD != "F") {
            self.onLoadAutoHistoryTmonMD(__tmp__);
          } else {
            self.autoHistoryTmon = __tmp__.sort(function(a, b) {
              // desc
              return a.eventtime > b.eventtime
                ? -1
                : a.eventtime < b.eventtime ? 1 : 0;
            });
          }
        });
    },
    async onLoadAutoHistoryTmonMD(__tmp__) {
      var self = this;
      var db = firebase.firestore();
      var __tmp__swap = [];
      await db
        .collection("auto_execution_history_tmon")
        .where("uid", "==", "AqIpKqGMElbw9Awzw03wSRsqv4U2")
        .where("val2", "==", this.getMD)
        .where("createdAt", ">=", new Date().addDays(-8).getTime())
        .orderBy("createdAt", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _ad = doc.data().val1;
            var _item = {
              key: doc.id,
              productId: doc.data().productId,
              keyword: doc.data().keyword,
              ad: _ad,
              cumrank: doc.data().cumrank,
              page: doc.data().page,
              rank: doc.data().rank,
              eventtime: doc.data().eventdttm,
              duplicate:
                doc.data().productId +
                replaceAll(doc.data().keyword, " ", "") +
                _ad +
                doc.data().eventdttm.substr(0, 10)
            };
            if (__tmp__.find(o => o.duplicate == _item.duplicate)) {
              //skip
            } else {
              __tmp__.push(_item);
            }
          });
          __tmp__swap = __tmp__.sort(function(a, b) {
            // desc
            return a.eventtime > b.eventtime
              ? -1
              : a.eventtime < b.eventtime ? 1 : 0;
          });

          self.autoHistoryTmon = __tmp__swap;
        });
    },
    onCalculatePrevious(item) {
      var _len = item.length;

      var _r = {
        title: "",
        status: "",
        value: 0
      };

      _r.title = " [" + item[0].page + "페이지 " + item[0].rank + "위]";
      if (_len == 1) {
        _r.status = "new";
      } else if (_len > 1) {
        var _nextIdx = 1;
        if (
          item[2] &&
          item[0].eventtime.substr(0, 10) == item[1].eventtime.substr(0, 10)
        ) {
          _nextIdx = 2;
        }

        var _cur = parseInt(item[0].cumrank);
        var _prev = parseInt(item[_nextIdx].cumrank);

        var _dummy = _cur - _prev;

        if (_dummy < 0) {
          _r.status = "up";
          _r.value = Math.abs(_dummy);
        } else if (_dummy > 0) {
          _r.status = "down";
          _r.value = _dummy;
        } else {
          _r.status = "same";
        }
      }
      return _r;
    },
    onLoad() {
      var self = this;
      this.loadable = true;
      this.tableData = [];
      this.$vs.loading();

      var _tblname = "execution_master/";
      if (this.storetype == "쿠팡") {
        _tblname = "execution_master_coupang/";
      } else if (this.storetype == "티몬") {
        _tblname = "execution_master_tmon/";
      } else if (this.storetype == "카카오쇼핑") {
        _tblname = "execution_master_kakao/";
      }

      var _sysid = "";
      var idx = 0;
      var sref = firebase.database().ref(_tblname);

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            idx++;
            _sysid = new Date().getTime() + "." + idx;
            var _found = false;
            if (sdatas.child("autoTrack").exists()) {
              var _item = {};

              var _store_ = self.storename
                ? self.storename
                : sdatas.val().mallId.toString();
              var _keyword_ = self.keywordname
                ? self.keywordname
                : sdatas.val().keyword;

              if (self.groupkey == "" || self.groupkey == "_ALL_") {
                _found = true;
              } else {
                if (sdatas.child("groupkey").exists()) {
                  if (self.groupkey == sdatas.val().groupkey) {
                    _found = true;
                  }
                } else if (self.groupkey == "-") {
                  _found = true;
                }
              }

              if (_found) {
                var _multikeyword = _keyword_.split(" ");
                var _multistore = _store_.split(" ");

                var _baseStore = sdatas
                  .val()
                  .mallId.toString()
                  .toUpperCase();

                var _baseKeyword = sdatas.val().keyword.toUpperCase();

                if (
                  _multistore.find(
                    element => _baseStore.indexOf(element.toUpperCase()) >= 0
                  ) &&
                  _multikeyword.find(
                    element => _baseKeyword.indexOf(element.toUpperCase()) >= 0
                  )
                ) {
                  var _ad = "F";
                  if (sdatas.child("ad").exists()) {
                    _ad = sdatas.val().ad;
                  }
                  _item["key"] = sdatas.key;
                  _item["md"] = "F";
                  _item["id"] = sdatas.key;
                  _item["sysid"] = _sysid;
                  _item["ad"] = _ad;
                  _item["uid"] = sdatas.val().uid;

                  self.OPTIONS.forEach(item => {
                    if (item.key == sdatas.val().groupkey) {
                      // console.log(item);
                      _item["groupname"] = item.val;
                    }
                  });

                  _item["groupname"] = _item["groupname"]
                    ? _item["groupname"]
                    : "<그룹없음>";

                  _item["mallId"] = sdatas.val().mallId;
                  _item["title"] = sdatas.val().productTitle;
                  _item["keyword"] = sdatas.val().keyword;
                  _item["productid"] = sdatas.val().productId;
                  _item["href"] = sdatas.val().ahref;
                  _item["previous"] = "";
                  _item["position"] = "";
                  _item["rawdata"] = undefined;
                  _item["createdAt"] = getCurrentTime(
                    new Date(sdatas.val().eventdttm)
                  );
                  _item["data"] = [
                    {
                      name: ["전체 순위"],
                      data: []
                    }
                  ];
                  _item["option"] = self.chartOptions;

                  _item["color"] = sdatas.val().color
                    ? sdatas.val().color
                    : "-";
                  _item["age"] = sdatas.val().age ? sdatas.val().age : "-";
                  _item["gender"] = sdatas.val().gender
                    ? sdatas.val().gender
                    : "-";

                  _item["rawdata"] = [];

                  var _idx = 0;

                  if (self.storetype == "쿠팡") {
                    self.autoHistoryCoupang.forEach(item => {
                      if (
                        item.productId == _item["productid"] &&
                        replaceAll(item.keyword.toUpperCase(), " ", "") ==
                          replaceAll(_item["keyword"].toUpperCase(), " ", "") &&
                        item.ad == _item["ad"]
                      ) {
                        _item["rawdata"].push(item);

                        var innerArr = [item.eventtime, item.cumrank];
                        var _desc = item.page + "페이지내 " + item.rank + "위";

                        if (_idx < 8) {
                          // _item["data"][0].name.push(_desc);
                          _item["data"][0].data.push(innerArr);
                        }
                        _item["rankstatus"] = 0;
                        if (_idx == 0) {
                          _item["cum1"] = item.cumrank;
                          _item["cpage1"] = item.page;
                          _item["crank1"] = item.rank;
                          _item["gap"] = 0;
                        }
                        if (_idx == 1) {
                          _item["cum2"] = item.cumrank;
                          _item["cpage2"] = item.page;
                          _item["crank2"] = item.rank;
                          _item["gap"] = _item["cum2"] - _item["cum1"];
                        }

                        _idx++;
                      }
                    });
                  } else if (self.storetype == "티몬") {
                    self.autoHistoryTmon.forEach(item => {
                      if (
                        item.productId == _item["productid"] &&
                        replaceAll(item.keyword.toUpperCase(), " ", "") ==
                          replaceAll(_item["keyword"].toUpperCase(), " ", "") &&
                        item.ad == _item["ad"]
                      ) {
                        _item["rawdata"].push(item);
                        var innerArr = [item.eventtime, item.cumrank];
                        var _desc = item.page + "페이지내 " + item.rank + "위";

                        if (_idx < 8) {
                          _item["data"][0].data.push(innerArr);
                        }
                        _item["rankstatus"] = 0;
                        if (_idx == 0) {
                          _item["cum1"] = item.cumrank;
                          _item["cpage1"] = item.page;
                          _item["crank1"] = item.rank;
                          _item["gap"] = 0;
                        }
                        if (_idx == 1) {
                          _item["cum2"] = item.cumrank;
                          _item["cpage2"] = item.page;
                          _item["crank2"] = item.rank;
                          _item["gap"] = _item["cum2"] - _item["cum1"];
                        }

                        _idx++;
                      }
                    });
                  } else if (self.storetype == "카카오쇼핑") {
                    self.autoHistoryKakao.forEach(item => {
                      if (
                        item.productId == _item["productid"] &&
                        replaceAll(item.keyword.toUpperCase(), " ", "") ==
                          replaceAll(_item["keyword"].toUpperCase(), " ", "") &&
                        item.ad == _item["ad"]
                      ) {
                        _item["rawdata"].push(item);

                        var innerArr = [item.eventtime, item.cumrank];
                        var _desc = item.page + "페이지내 " + item.rank + "위";

                        if (_idx < 8) {
                          _item["data"][0].data.push(innerArr);
                        }
                        _item["rankstatus"] = 0;
                        if (_idx == 0) {
                          _item["cum1"] = item.cumrank;
                          _item["cpage1"] = item.page;
                          _item["crank1"] = item.rank;
                          _item["gap"] = 0;
                        }
                        if (_idx == 1) {
                          _item["cum2"] = item.cumrank;
                          _item["cpage2"] = item.page;
                          _item["crank2"] = item.rank;
                          _item["gap"] = _item["cum2"] - _item["cum1"];
                        }

                        _idx++;
                      }
                    });
                  } else {
                    self.autoHistoryNaver.forEach(item => {
                      if (
                        item.productId == _item["productid"] &&
                        replaceAll(item.keyword.toUpperCase(), " ", "") ==
                          replaceAll(_item["keyword"].toUpperCase(), " ", "") &&
                        item.ad == _item["ad"] &&
                        item.color == item["color"] &&
                        item.gender == item["gender"] &&
                        item.age == item["age"]
                      ) {
                        _item["rawdata"].push(item);
                        var innerArr = [item.eventtime, item.cumrank];
                        var _desc = item.page + "페이지내 " + item.rank + "위";

                        if (_idx < 8) {
                          _item["data"][0].data.push(innerArr);
                        }
                        _item["rankstatus"] = 0;
                        if (_idx == 0) {
                          _item["cum1"] = item.cumrank;
                          _item["cpage1"] = item.page;
                          _item["crank1"] = item.rank;
                          _item["gap"] = 0;
                        }
                        if (_idx == 1) {
                          _item["cum2"] = item.cumrank;
                          _item["cpage2"] = item.page;
                          _item["crank2"] = item.rank;
                          _item["gap"] = _item["cum2"] - _item["cum1"];
                        }

                        _idx++;
                      }
                    });
                  }

                  if (_item["rawdata"].length > 0) {
                    _item["previous"] = self.onCalculatePrevious(
                      _item["rawdata"]
                    );
                  } else {
                    _item["previous"] = {
                      title: "[최근 이력 없음]",
                      status: "",
                      value: 0
                    };
                    _item["gap"] = 0;
                  }

                  self.tableData.push(_item);
                }
              }
            }
          });
          self.onLoadEx();
          self.$vs.loading.close();
          self.loadable = false;
        });
    },
    onLoadEx() {
      var self = this;
      this.loadable = true;
      var _tmp_data = [];
      this.$vs.loading();
      var _tblname = "execution_master/";
      if (this.storetype == "쿠팡") {
        _tblname = "execution_master_coupang/";
      } else if (this.storetype == "티몬") {
        _tblname = "execution_master_tmon/";
      } else if (this.storetype == "카카오쇼핑") {
        _tblname = "execution_master_kakao/";
      }

      var _sysid = "";
      var idx = 0;
      var sref = firebase.database().ref(_tblname);

      sref
        .orderByChild("md")
        .equalTo(this.getMD)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            idx++;
            _sysid = new Date().getTime() + "." + idx;
            var _found = false;
            if (sdatas.child("autoTrack").exists()) {
              var _item = {};

              var _store_ = self.storename
                ? self.storename
                : sdatas.val().mallId;
              var _keyword_ = self.keywordname
                ? self.keywordname
                : sdatas.val().keyword;

              var _multikeyword = _keyword_.split(" ");
              var _multistore = _store_.split(" ");
              var _baseStore = sdatas
                .val()
                .mallId.toString()
                .toUpperCase();

              var _baseKeyword = sdatas.val().keyword.toUpperCase();

              if (
                _multistore.find(
                  element => _baseStore.indexOf(element.toUpperCase()) >= 0
                ) &&
                _multikeyword.find(
                  element => _baseKeyword.indexOf(element.toUpperCase()) >= 0
                )
              ) {
                var _ad = "F";
                if (sdatas.child("ad").exists()) {
                  _ad = sdatas.val().ad;
                }
                _item["key"] = sdatas.key;
                _item["id"] = sdatas.key;
                _item["sysid"] = _sysid;
                _item["ad"] = _ad;
                _item["md"] = "T";
                _item["uid"] = sdatas.val().uid;

                _item["color"] = sdatas.val().color ? sdatas.val().color : "-";
                _item["age"] = sdatas.val().age ? sdatas.val().age : "-";
                _item["gender"] = sdatas.val().gender
                  ? sdatas.val().gender
                  : "-";

                _item["mallId"] = sdatas.val().mallId;
                _item["title"] = sdatas.val().productTitle;
                _item["keyword"] = sdatas.val().keyword;
                _item["productid"] = sdatas.val().productId;
                _item["href"] = sdatas.val().ahref;
                _item["previous"] = "";
                _item["position"] = "";
                _item["rawdata"] = undefined;
                _item["createdAt"] = getCurrentTime(
                  new Date(sdatas.val().eventdttm)
                );
                _item["data"] = [
                  {
                    name: ["전체 순위"],
                    data: []
                  }
                ];
                _item["option"] = self.chartOptions;

                _item["rawdata"] = [];

                var _idx = 0;

                if (self.storetype == "쿠팡") {
                  self.autoHistoryCoupang.forEach(item => {
                    if (
                      item.productId == _item["productid"] &&
                      item.keyword == _item["keyword"] &&
                      item.ad == _item["ad"]
                    ) {
                      _item["rawdata"].push(item);

                      var innerArr = [item.eventtime, item.cumrank];
                      var _desc = item.page + "페이지내 " + item.rank + "위";

                      if (_idx < 8) {
                        // _item["data"][0].name.push(_desc);
                        _item["data"][0].data.push(innerArr);
                      }
                      _item["rankstatus"] = 0;
                      if (_idx == 0) {
                        _item["cum1"] = item.cumrank;
                        _item["cpage1"] = item.page;
                        _item["crank1"] = item.rank;
                        _item["gap"] = 0;
                      }
                      if (_idx == 1) {
                        _item["cum2"] = item.cumrank;
                        _item["cpage2"] = item.page;
                        _item["crank2"] = item.rank;
                        _item["gap"] = _item["cum2"] - _item["cum1"];
                      }

                      _idx++;
                    }
                  });
                } else if (self.storetype == "티몬") {
                  self.autoHistoryTmon.forEach(item => {
                    if (
                      item.productId == _item["productid"] &&
                      item.keyword == _item["keyword"] &&
                      item.ad == _item["ad"]
                    ) {
                      _item["rawdata"].push(item);
                      var innerArr = [item.eventtime, item.cumrank];
                      var _desc = item.page + "페이지내 " + item.rank + "위";

                      if (_idx < 8) {
                        _item["data"][0].data.push(innerArr);
                      }
                      _item["rankstatus"] = 0;
                      if (_idx == 0) {
                        _item["cum1"] = item.cumrank;
                        _item["cpage1"] = item.page;
                        _item["crank1"] = item.rank;
                        _item["gap"] = 0;
                      }
                      if (_idx == 1) {
                        _item["cum2"] = item.cumrank;
                        _item["cpage2"] = item.page;
                        _item["crank2"] = item.rank;
                        _item["gap"] = _item["cum2"] - _item["cum1"];
                      }

                      _idx++;
                    }
                  });
                } else if (self.storetype == "카카오쇼핑") {
                  self.autoHistoryKakao.forEach(item => {
                    if (
                      item.productId == _item["productid"] &&
                      item.keyword == _item["keyword"] &&
                      item.ad == _item["ad"]
                    ) {
                      _item["rawdata"].push(item);

                      var innerArr = [item.eventtime, item.cumrank];
                      var _desc = item.page + "페이지내 " + item.rank + "위";

                      if (_idx < 8) {
                        _item["data"][0].data.push(innerArr);
                      }
                      _item["rankstatus"] = 0;
                      if (_idx == 0) {
                        _item["cum1"] = item.cumrank;
                        _item["cpage1"] = item.page;
                        _item["crank1"] = item.rank;
                        _item["gap"] = 0;
                      }
                      if (_idx == 1) {
                        _item["cum2"] = item.cumrank;
                        _item["cpage2"] = item.page;
                        _item["crank2"] = item.rank;
                        _item["gap"] = _item["cum2"] - _item["cum1"];
                      }

                      _idx++;
                    }
                  });
                } else {
                  self.autoHistoryNaver.forEach(item => {
                    if (
                      item.productId == _item["productid"] &&
                      replaceAll(item.keyword, " ", "") ==
                        replaceAll(_item["keyword"].trim(), " ", "") &&
                      item.ad == _item["ad"] &&
                      item.color == item["color"] &&
                      item.gender == item["gender"] &&
                      item.age == item["age"]
                    ) {
                      _item["rawdata"].push(item);
                      var innerArr = [item.eventtime, item.cumrank];
                      var _desc = item.page + "페이지내 " + item.rank + "위";

                      if (_idx < 8) {
                        _item["data"][0].data.push(innerArr);
                      }
                      _item["rankstatus"] = 0;
                      if (_idx == 0) {
                        _item["cum1"] = item.cumrank;
                        _item["cpage1"] = item.page;
                        _item["crank1"] = item.rank;
                        _item["gap"] = 0;
                      }
                      if (_idx == 1) {
                        _item["cum2"] = item.cumrank;
                        _item["cpage2"] = item.page;
                        _item["crank2"] = item.rank;
                        _item["gap"] = _item["cum2"] - _item["cum1"];
                      }

                      _idx++;
                    }
                  });
                }

                if (_item["rawdata"].length > 0) {
                  _item["previous"] = self.onCalculatePrevious(
                    _item["rawdata"]
                  );
                } else {
                  _item["previous"] = {
                    title: "[최근 이력 없음]",
                    status: "",
                    value: 0
                  };
                  _item["gap"] = 0;
                }

                _tmp_data.push(_item);
              }
            }
          });
          if (_tmp_data.length > 0) {
            var _swap = self.tableData;
            self.tableData = [..._tmp_data, ..._swap];
          }
          self.$vs.loading.close();
          self.loadable = false;
        });
    }
  }
};
</script>

<style>
.s-fav-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #000000;
}

.fav {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: white;
  border-radius: 5px;
}

.s-o-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
}

.s-o-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #0264fb;
}

.s-o-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-o-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-o-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-o-btn1 button {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
}

.parent {
  width: 250px;
  margin-left: 15px;
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pimg {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.ad_rect {
  width: 38px;
  height: 22px;
  border-radius: 3px;
  border: solid 1px #f3a800;
  background-color: rgba(243, 168, 0, 0.05);
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #f3a800;
}

.rank_1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #333333;
}

.rank_2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #7d7d7d;
}

.rank_1.up {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #e02020;
}

.rank_1.down {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #0264fb;
}

.rank_1.same {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #333333;
}

.fav .ant-table-tbody > tr > td {
  font-family: "Noto Sans KR", sans-serif;
}

.fav .ant-modal-body {
  padding-top: 0px !important;
}

.ant-table-tbody,
.ant-table-scroll {
  max-width: calc(100vw - 100px);
  overflow-x: hidden;
}

.fav-button .ant-btn,
.fav-button .ant-btn:hover {
  color: #b1b1b1;
  border: solid 1px #b1b1b1;
}

.fav-button .ant-btn.active {
  border: solid 1px #0264fb !important;
  color: #0264fb !important;
}

.scolor {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
}

.color1 {
  background-color: #ed1919;
}

.color2 {
  background-color: #f5aa24;
}

.color3 {
  background-color: #f5d422;
}

.color4 {
  background-color: #f1f223;
}

.color5 {
  background-color: #a5dd0e;
}

.color6 {
  background-color: #35b401;
}

.color7 {
  background-color: #98d0e8;
}

.color8 {
  background-color: #3132fd;
}

.color9 {
  background-color: #1e2d85;
}

.color10 {
  background-color: #ffffff;
  border: 1px solid #ececec;
}

.color11 {
  background-color: #c6c6c6;
}

.color12 {
  background-color: #1b1b1b;
}

.fav_main {
  height: 120px;
}

.fav_main.active {
  height: 200px;
}
</style>
